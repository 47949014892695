var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"Web3infosmartcontracts wrapper",attrs:{"id":"article"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('home.web3InfoSmartContracts.title'))+" ")]),_c('article',[_c('p',{staticClass:"contract"},[_c('span',{staticClass:"contract__title"},[_vm._v(_vm._s(_vm.$t('home.web3InfoSmartContracts.description0.title')))]),_vm._l((_vm.$t(
          'home.web3InfoSmartContracts.description0.items'
        )),function(item,idx){return _c('span',{key:idx,staticClass:"contract__item"},[_vm._v(_vm._s(item.text)+" - "+_vm._s(item.contract)+" "),_c('BaseIcon',{staticClass:"copy",attrs:{"name":"copy"},on:{"event":function($event){return _vm.handleClipboardCopy(item.contract)}}})],1)})],2),_c('p',{staticClass:"contract"},[_c('span',{staticClass:"contract__title"},[_vm._v(_vm._s(_vm.$t('home.web3InfoSmartContracts.description1.title')))]),_vm._l((_vm.$t(
          'home.web3InfoSmartContracts.description1.items'
        )),function(item,idx){return _c('span',{key:idx,staticClass:"contract__item"},[_vm._v(_vm._s(item.text)+" - "+_vm._s(item.contract)+" "),_c('BaseIcon',{staticClass:"copy",attrs:{"name":"copy"},on:{"event":function($event){return _vm.handleClipboardCopy(item.contract)}}})],1)})],2),_c('p',{staticClass:"contract"},[_c('span',{staticClass:"contract__title"},[_vm._v(_vm._s(_vm.$t('home.web3InfoSmartContracts.description2.title')))]),_vm._l((_vm.$t(
          'home.web3InfoSmartContracts.description2.items'
        )),function(item,idx){return _c('span',{key:idx,staticClass:"contract__item"},[_vm._v(_vm._s(item.text)+" - "+_vm._s(item.contract)+" "),_c('BaseIcon',{staticClass:"copy",attrs:{"name":"copy"},on:{"event":function($event){return _vm.handleClipboardCopy(item.contract)}}})],1)})],2),_c('p',{staticClass:"contract"},[_c('span',{staticClass:"contract__title"},[_vm._v(_vm._s(_vm.$t('home.web3InfoSmartContracts.description3.title')))]),_vm._l((_vm.$t(
          'home.web3InfoSmartContracts.description3.items'
        )),function(item,idx){return _c('span',{key:idx,staticClass:"contract__item"},[_vm._v(_vm._s(item.text)+" - "+_vm._s(item.contract)+" "),_c('BaseIcon',{staticClass:"copy",attrs:{"name":"copy"},on:{"event":function($event){return _vm.handleClipboardCopy(item.contract)}}})],1)})],2),_c('p',{staticClass:"contract"},[_c('span',{staticClass:"contract__title"},[_vm._v(_vm._s(_vm.$t('home.web3InfoSmartContracts.description4.title')))]),_vm._l((_vm.$t(
          'home.web3InfoSmartContracts.description4.items'
        )),function(item,idx){return _c('span',{key:idx,staticClass:"contract__item"},[_vm._v(_vm._s(item.text)+" - "+_vm._s(item.contract)+" "),_c('BaseIcon',{directives:[{name:"show",rawName:"v-show",value:(item.contract.length > 10),expression:"item.contract.length > 10"}],staticClass:"copy",attrs:{"name":"copy"},on:{"event":function($event){return _vm.handleClipboardCopy(item.contract)}}})],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="Web3infosmartcontracts wrapper" id="article">
    <h1>
      {{ $t('home.web3InfoSmartContracts.title') }}
    </h1>

    <article>
      <!-- SYSTEM -->
      <p class="contract">
        <span class="contract__title">{{
          $t('home.web3InfoSmartContracts.description0.title')
        }}</span>
        <span
          class="contract__item"
          v-for="(item, idx) in $t(
            'home.web3InfoSmartContracts.description0.items'
          )"
          :key="idx"
          >{{ item.text }} - {{ item.contract }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(item.contract)"
          />
        </span>
      </p>
      <!-- TACTILE -->
      <p class="contract">
        <span class="contract__title">{{
          $t('home.web3InfoSmartContracts.description1.title')
        }}</span>
        <span
          class="contract__item"
          v-for="(item, idx) in $t(
            'home.web3InfoSmartContracts.description1.items'
          )"
          :key="idx"
          >{{ item.text }} - {{ item.contract }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(item.contract)"
          />
        </span>
      </p>
      <!-- UNITEVERSE -->
      <p class="contract">
        <span class="contract__title">{{
          $t('home.web3InfoSmartContracts.description2.title')
        }}</span>
        <span
          class="contract__item"
          v-for="(item, idx) in $t(
            'home.web3InfoSmartContracts.description2.items'
          )"
          :key="idx"
          >{{ item.text }} - {{ item.contract }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(item.contract)"
          />
        </span>
      </p>
      <!-- Royalty NFT -->
      <p class="contract">
        <span class="contract__title">{{
          $t('home.web3InfoSmartContracts.description3.title')
        }}</span>
        <span
          class="contract__item"
          v-for="(item, idx) in $t(
            'home.web3InfoSmartContracts.description3.items'
          )"
          :key="idx"
          >{{ item.text }} - {{ item.contract }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(item.contract)"
          />
        </span>
      </p>
      <!-- TOKENS / COIN -->
      <p class="contract">
        <span class="contract__title">{{
          $t('home.web3InfoSmartContracts.description4.title')
        }}</span>
        <span
          class="contract__item"
          v-for="(item, idx) in $t(
            'home.web3InfoSmartContracts.description4.items'
          )"
          :key="idx"
          >{{ item.text }} - {{ item.contract }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(item.contract)"
            v-show="item.contract.length > 10" 
          />
        </span>
      </p>
    </article>
  </section>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { copyToClipboard } from '@/helpers/clipboard'

export default {
  name: 'Web3infosmartcontracts',

  components: {
    BaseIcon
  },

  methods: {
    handleClipboardCopy (text) {
      if (!text) return
      // navigator.clipboard.writeText(text)
      copyToClipboard(text)
        .then(() => {
          this.$toast.success(`${text} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.Web3infosmartcontracts {
  width: 100%;
  height: auto;
}

.contract {
  padding-top: 30px;
  &:first-child {
    padding-top: 0;
  }
  &__title {
    display: block;
  }
  &__item {
    display: block;
    word-break: break-all;
  }
}

.copy {
  margin-left: 5px;
  margin-top: 2px;
}
</style>
